<template>
  <div>{{ $t("Dashboard") }}</div>
</template>

<script>
export default {
  i18n: {
    messages: {
      en: {
        Dashboard: "Purchase Dashboard",
      },
      vi: {
        Dashboard: "Trang mua hàng",
      },
    },
  },
};
</script>
